import request from './request';
import {
  DOWNLOAD_EVENT_API,
  EVENT_CATEGORIES_API,
  EVENTS_LOCATIONS_API,
  EVENTS_API,
  HOMEPAGE_EVENTS_API,
} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_events_list(params = {}) {
    return request({
      url: EVENTS_API,
      method: API_METHODS.GET,
      params,
    });
  },
  get_event(id) {
    return request({
      url: `${EVENTS_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  download_event({id}) {
    return request({
      url: `${DOWNLOAD_EVENT_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  get_event_categories(params = {}) {
    return request({
      url: EVENT_CATEGORIES_API,
      method: API_METHODS.GET,
      params,
    });
  },
  get_event_locations() {
    return request({
      url: EVENTS_LOCATIONS_API,
      method: API_METHODS.GET,
    });
  },
  get_home_page_events(params = {}) {
    return request({
      url: HOMEPAGE_EVENTS_API,
      method: API_METHODS.GET,
      params,
    });
  },
};
